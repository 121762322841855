import React from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { PencilSimple } from 'phosphor-react';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { TableV2 } from 'src/components/TableV2';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';
import { getColorByFirstLetter } from 'src/utils/colors/getColorByFirstLetter';

import {
  EditVariablesButton,
  NameTd,
  TagTd,
  VariableTd,
  WorkspaceVariablesContent,
} from './styles';
import {
  ProjectsResponse,
  SelectedVariablesProps,
  SelectedVariablesResponse,
  Tags,
} from './types';
import { ParamsProps } from '../../types';

export const SelectedVariables: React.FC<SelectedVariablesProps> = ({
  publishLoading,
  loadingEditionPermission,
  handleEditWorkspace,
}) => {
  const { t: translate } = useTranslation();

  const { workspace } = useSelector((state: RootState) => state);
  const { id: workspaceId } = useParams<ParamsProps>();

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    isFetching: isFetchingProjects,
    isError: isErrorProjects,
  } = useQuery(
    ['workspace staging area', workspaceId, 'projects'],
    async (): Promise<SelectedVariablesResponse[]> => {
      const { data } = await apiWorkspace.get<ProjectsResponse>(
        `/workspaces/${workspaceId}/staging-area/projects`,
      );

      const allSelectedVariables: SelectedVariablesResponse[] = [];
      const allTags: Tags = {};

      for (const info of data.projects) {
        if (info.source === 'projects') {
          allSelectedVariables.push({ ...info, id: info.project_id });
        } else if (allTags[info.tag]) {
          allTags[info.tag].push(info.name);
        } else {
          allTags[info.tag] = [info.name];
        }
      }

      const tagKeys = Object.keys(allTags);

      for (const key of tagKeys) {
        allSelectedVariables.push({
          name: key,
          y_labels: allTags[key],
          icon_url: '',
          id: key.toLowerCase().replaceAll(' ', '-'),
          source: 'external_series',
        });
      }

      return allSelectedVariables;
    },
    {
      staleTime: ms('3 min'),
    },
  );

  return (
    <WorkspaceVariablesContent>
      <Card
        textCard={translate('workspaceControlPanelSelectedVariablesTitle')}
        textDescription={translate(
          'workspaceControlPanelSelectedVariablesDescription',
        )}
      />

      <EditVariablesButton>
        <ButtonRounded
          icon={<PencilSimple />}
          disabled={
            workspace.status === undefined ||
            workspace.status === 'publishing' ||
            publishLoading
          }
          label={translate('workspaceControlPanelEdit')}
          onClick={handleEditWorkspace}
          style={loadingEditionPermission ? { cursor: 'wait' } : undefined}
          data-testid="edit-workspace-button"
          className="naked-button-control-panel"
        />
      </EditVariablesButton>

      {isErrorProjects ? (
        <ContainerMaintenance content="" data-testid="project-list-error" />
      ) : (
        <TableV2.Container className="container-variables-table">
          <TableV2.Root>
            <TableV2.Thead>
              <TableV2.Tr>
                <TableV2.Th>
                  {translate('workspaceControlPanelProjectTagColumn')}
                </TableV2.Th>
                <TableV2.Th>{translate('variables')}</TableV2.Th>
              </TableV2.Tr>
            </TableV2.Thead>

            <TableV2.Tbody>
              {isLoadingProjects || isFetchingProjects || !projectsData
                ? Array.from({ length: 5 }, (_, number) => number).map(
                    (number) => (
                      <TableV2.Tr
                        key={number}
                        data-testid={`workspace-projects-loading-${number}`}
                      >
                        <TableV2.Td>
                          <ContainerSkeleton
                            withLoading={false}
                            style={{ width: '100%', height: '1.5rem' }}
                          />
                        </TableV2.Td>
                        <TableV2.Td>
                          <ContainerSkeleton
                            withLoading={false}
                            style={{ width: '100%', height: '1.5rem' }}
                          />
                        </TableV2.Td>
                      </TableV2.Tr>
                    ),
                  )
                : projectsData.map(
                    ({ icon_url, name, y_labels, id, source }) => (
                      <TableV2.Tr key={id}>
                        <TableV2.Td>
                          {source === 'external_series' ? (
                            <TagTd
                              data-testid={`tag-name-${id}`}
                              tagColor={getColorByFirstLetter(name)}
                            >
                              {name}
                            </TagTd>
                          ) : (
                            <NameTd>
                              <img
                                src={
                                  icon_url && checkIconURL(icon_url)
                                    ? icon_url
                                    : arrayIcon[0]
                                }
                                alt="Project icon"
                                data-testid={`project-icon-${id}`}
                              />
                              <p data-testid={`project-name-${id}`}>{name}</p>
                            </NameTd>
                          )}
                        </TableV2.Td>
                        <TableV2.Td>
                          <VariableTd
                            data-tooltip-html={
                              y_labels.length > 1
                                ? y_labels.slice(1).toString()
                                : ''
                            }
                            data-tooltip-id="workspace-control-panel-variable-tooltip"
                            data-testid={`project-variables-${id}`}
                          >
                            {y_labels[0]}{' '}
                            {y_labels.length > 1 &&
                              `+${y_labels.length - 1} ${translate(
                                'variables',
                              )}`}
                          </VariableTd>
                        </TableV2.Td>
                      </TableV2.Tr>
                    ),
                  )}
            </TableV2.Tbody>
          </TableV2.Root>
        </TableV2.Container>
      )}
    </WorkspaceVariablesContent>
  );
};

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { WorkspaceConfigContext } from 'src/workspaces/contexts/WorkspaceConfigContext';
import { Tooltip } from 'react-tooltip';
import { DownloadSimple, PencilSimple, Question, X } from 'phosphor-react';
import { InputFile } from 'src/models/pages/Modelling/TimeSeries/components/InputFile';
import { Button } from 'src/components/Button';
import {
  ColDef,
  ExcelExportParams,
  ExcelStyle,
  ICellRendererParams,
} from 'ag-grid-community';
import { AgGridTable } from 'src/components/AgGridTable';
import { AgGridReact } from 'ag-grid-react';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import light from 'src/styles/themes/light';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { useNavigate } from 'react-router-dom';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

import {
  CategorizationActions,
  Container,
  Content,
  FileButtons,
  Subtitle,
  TableContainer,
  UploadContainer,
  MarketShareToggle,
} from './styles';
import { Filter, SheetCell, Step4Props, TableRowData } from './types';
import { StepFooter } from '../StepFooter';
import { FileRequirementsModal } from '../Modal/FileRequirements';
import { ConfirmCategorizationDiscardModal } from '../Modal/ConfirmCategorizationDiscard';
import { AggregationConfiguration } from '../AggregationConfiguration';
import { DataType } from '../DataType';

export const Step4: React.FC<Step4Props> = ({ setStep, saveWorkspace }) => {
  const [currentFile, setCurrentFile] = useState<File>();
  const [manualFilters, setManualFilters] = useState<Filter[]>([]);

  const [tableRowData, setTableRowData] = useState<TableRowData[]>([]);
  const [tableColumnDefs, setTableColumnDefs] = useState<ColDef[]>([]);
  const [tableRendered, setTableRendered] = useState(false);

  const [loadingTableData, setLoadingTableData] = useState(false);

  const [editingCategorization, setEditingCategorization] = useState(false);

  const [showRequirementsModal, setShowRequirementsModal] = useState(false);
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);

  const [fileIsEmpty, setFileIsEmpty] = useState(false);

  const tableRef = useRef<AgGridReact>(null);

  const {
    isEdition,
    frequency,
    name,
    series,
    categorization,
    saveCategorization,
    file,
    setFile,
    enableMarketShare,
    setEnableMarketShare,
    isErrorStagingAreaHierarchies,
    isErrorStagingAreaAggregations,
    isLoadingStagingAreaHierarchies,
    isLoadingStagingAreaAggregations,
    workspaceId,
    updatingCategorization,
    yTypesDefinedByUser,
  } = useContext(WorkspaceConfigContext);
  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  const [loadingCategorization, setLoadingCategorization] = useState(isEdition);

  const handleDownloadTemplate = () => {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('Sheet1');
    0;

    // Definir cabeçalhos para a planilha
    worksheet.columns = [
      {
        header: translate('createWorkspaceTemplateVariable'),
        key: 'variable',
      },
      {
        header: translate('type'),
        key: 'variable_type',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 1`,
        key: 'level_1',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 2`,
        key: 'level_2',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 3`,
        key: 'level_3',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 4`,
        key: 'level_4',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 5`,
        key: 'level_5',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 6`,
        key: 'level_6',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 7`,
        key: 'level_7',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 8`,
        key: 'level_8',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 9`,
        key: 'level_9',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 10`,
        key: 'level_10',
      },
    ];

    series.forEach((serie) => {
      if (serie.selected) {
        worksheet.addRow({
          variable: serie.yLabel,
          variable_type: null,
          level_1: null,
          level_2: null,
          level_3: null,
          level_4: null,
          level_5: null,
          level_6: null,
          level_7: null,
          level_8: null,
          level_9: null,
          level_10: null,
        });
      }
    });

    const dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: [
        `"MarketSize,SellOut,${translate(
          'createWorkspaceTemplateOthers',
        )},${yTypesDefinedByUser.toString()}"`,
      ],
    };

    worksheet.getColumn('B').eachCell((cell, rowNumber) => {
      if (rowNumber !== 1) {
        //@ts-expect-error:ignora
        cell.dataValidation = dataValidation;
      }
    });

    const templateName = name
      ? `${name.replaceAll(' ', '-').toLowerCase().trim()}-template`
      : 'template';

    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${templateName}.xlsx`,
      );
    });
  };

  const handleExportTable = () => {
    if (tableRendered) {
      const config: ExcelExportParams = {
        sheetName: translate('variables'),
        fileName: `${name} - ${translate(
          'createWorkspaceVariableCategorization',
        )}`,
        processGroupHeaderCallback(params) {
          const columnName = params.api
            .getDisplayNameForColumnGroup(params.columnGroup, null)
            .toLowerCase();

          if (['variável', 'variable'].includes(columnName))
            return translate('createWorkspaceTemplateVariable');

          if (['tipo', 'type'].includes(columnName))
            return translate('createWorkspaceTemplateVariable');

          return columnName;
        },
      };

      tableRef.current!.api.exportDataAsExcel(config);
    }
  };

  const handleDiscard = (clearFilters?: boolean) => {
    if (clearFilters || !editingCategorization) {
      setEnableMarketShare(false);
      setManualFilters([]);
      setCurrentFile(undefined);
      setFile(undefined);
      setTableRowData([]);
      setTableColumnDefs([]);
      saveCategorization(undefined);
    } else {
      setCurrentFile(file);
      setEditingCategorization(false);
    }
  };

  const setTableDataFromManualFilter = () => {
    if (categorization) {
      const colDefs: ColDef[] = [
        {
          headerName: translate('createWorkspaceTemplateVariable'),
          field: 'variable',
          pinned: true,
          cellClass: 'variable-cell',
          headerClass: 'variable-header',
        },
      ];

      categorization.hierarchies.forEach((hierarchy) => {
        colDefs.push({
          field: hierarchy,
          cellClass: 'filter-cell',
          headerClass: 'filter-header',
        });
      });

      const ysLabel = Object.keys(categorization.ys);

      const rows: TableRowData[] = [];

      ysLabel.forEach((y) => {
        const yRow: TableRowData = { variable: y };

        categorization.ys[y].forEach((option) => {
          yRow[option.name] = option.value ?? '';
        });

        rows.push(yRow);
      });

      setTableRowData(rows);
      setTableColumnDefs(colDefs);
    }
  };

  const backStep = () => {
    setFile(currentFile);
    setStep(3);
  };

  const nextStep = () => {
    saveWorkspace();
  };

  const formattedDataCy = (value?: string) =>
    value?.toLowerCase()?.replaceAll(' ', '-');

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 140,
      width: 250,
      autoHeight: true,
      suppressMovable: true,
      lockPinned: true,
      sortable: false,
      unSortIcon: true,
      resizable: true,
      suppressHeaderMenuButton: true,
      cellRenderer: (param: ICellRendererParams) => (
        <div
          data-testid={`cell-row-${formattedDataCy(
            param.data.variable ??
              param.data.variavel ??
              param.data.Variable ??
              param.data.Variavel,
          )}-column-${formattedDataCy(param.column?.getColId())}`}
        >
          {param.value}
        </div>
      ),
    }),
    [],
  );

  const excelStyle: ExcelStyle[] = useMemo(() => {
    const excelBorders = {
      borderBottom: {
        color: light.colors.gray3,
      },
      borderTop: {
        color: light.colors.gray3,
      },
      borderLeft: {
        color: light.colors.gray3,
      },
      borderRight: {
        color: light.colors.gray3,
      },
    };

    const defaultOptions: ExcelStyle[] = [
      {
        id: 'variable-header',
        alignment: {
          horizontal: 'Center',
        },
        borders: excelBorders,
        interior: { color: light.colors.gray1, pattern: 'Solid' },
      },
      {
        id: 'filter-header',
        alignment: {
          horizontal: 'Center',
        },
        borders: excelBorders,
        interior: { color: light.colors.gray1, pattern: 'Solid' },
      },
      {
        id: 'variable-cell',
        borders: excelBorders,
      },
      {
        id: 'filter-cell',
        borders: excelBorders,
      },
    ];

    return defaultOptions;
  }, []);

  useEffect(() => {
    const loadDataFromExcel = async (): Promise<{
      columns: SheetCell[];
      data: TableRowData[];
    }> =>
      new Promise((resolve, reject) => {
        if (!currentFile) {
          reject(new Error('File not found'));
        }

        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();

        let columns: SheetCell[] = [];
        const data: TableRowData[] = [];

        reader.readAsArrayBuffer(currentFile as File);
        reader.onload = async () => {
          const buffer = reader.result;

          await workbook.xlsx.load(buffer as Buffer).then((wb) => {
            wb.worksheets[0]?.eachRow((row, rowIndex) => {
              if (row && Array.isArray(row.values)) {
                if (rowIndex === 1) {
                  columns = row.values.map((value) => value as SheetCell);
                } else if (rowIndex !== 0 && columns.length) {
                  const rowData: TableRowData = {};

                  row.values.forEach((value, index) => {
                    const colName = columns[index];

                    if (colName && value) {
                      rowData[colName] = value as string;
                    }
                  });

                  data.push(rowData);
                }
              }
            });

            resolve({ columns, data });
          });
        };
      });

    const formatTableData = (columns: SheetCell[], data: TableRowData[]) => {
      const colDefs: ColDef[] = [];

      columns.forEach((col) => {
        if (col) {
          const colLowerCase = col?.toLowerCase() ?? '';

          const isVariable = ['variável', 'variable'].includes(colLowerCase);

          const isVariableType = ['type', 'tipo'].includes(colLowerCase);

          colDefs.push({
            headerName: isVariable
              ? translate('createWorkspaceTemplateVariable')
              : isVariableType
              ? translate('type')
              : col ?? '',
            field: col ?? '',
            pinned: isVariable,
            cellClass: 'filter-cell',
            headerClass: 'filter-header',
          });
        }
      });

      setTableRowData(data);
      setTableColumnDefs(colDefs);
    };

    const formatFileToTableRender = async () => {
      setLoadingTableData(true);

      const { columns, data } = await loadDataFromExcel();

      if (!data.length) {
        setFileIsEmpty(true);
      } else {
        setEditingCategorization(false);
        setManualFilters([]);
        saveCategorization(undefined);

        formatTableData(columns, data);

        setLoadingTableData(false);
      }
    };

    if (currentFile) {
      setFileIsEmpty(false);
      formatFileToTableRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFile]);

  useEffect(() => {
    if (tableRendered) {
      const updatedTableColDefs = [...tableColumnDefs];

      updatedTableColDefs.forEach((col) => {
        const field = col?.field?.toLowerCase() ?? '';

        if (col.headerName) {
          if (['variável', 'variable'].includes(field)) {
            col.headerName = translate('createWorkspaceTemplateVariable');
          }
          if (['type', 'tipo'].includes(field)) {
            col.headerName = translate('type');
          }
        }
      });

      tableRef.current?.api.setGridOption('columnDefs', updatedTableColDefs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translate]);

  useEffect(() => {
    if (categorization?.hierarchies.length && !manualFilters.length) {
      const formattedFilters: Filter[] = [];

      const ysLabel = Object.keys(categorization.ys);

      categorization.hierarchies.forEach((hierarchy) => {
        formattedFilters.push({ name: hierarchy, options: [] });
      });

      ysLabel.forEach((y) => {
        categorization.ys[y].forEach((option, index) => {
          if (formattedFilters[index]?.options) {
            const optionIndex = formattedFilters[index]?.options?.findIndex(
              ({ name: optionName }) => optionName === option.value,
            );

            if (optionIndex === -1 && option.value) {
              formattedFilters[index].options.push({
                name: option.value,
                id: String(formattedFilters[index].options.length + 1),
                ys: [y],
              });
            } else if (option.value) {
              formattedFilters[index].options[optionIndex].ys.push(y);
            }
          }
        });
      });

      setManualFilters(JSON.parse(JSON.stringify(formattedFilters)));
    }

    if (categorization?.hierarchies.length) {
      setLoadingTableData(true);

      setTableDataFromManualFilter();

      setLoadingTableData(false);
    }

    if (!isLoadingStagingAreaHierarchies) {
      setLoadingCategorization(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorization]);

  useEffect(() => {
    setCurrentFile(file);
  }, [file]);

  useEffect(() => {
    setTableRendered(false);
  }, [loadingTableData]);

  useEffect(() => {
    if (currentFile && !editingCategorization) {
      setFile(currentFile);
    }
  }, [currentFile, editingCategorization, setFile]);

  useEffect(() => {
    if (editingCategorization) {
      setTableRendered(false);
    }
  }, [editingCategorization]);

  const marketShareDisabled = ['daily', 'weekly', 'fortnightly'].includes(
    frequency ?? '',
  );

  return (
    <Container className="containerLinear" data-testid="container-step-4">
      <Card
        textCard={translate('createWorkspaceVariableCategorization')}
        textDescription={translate(
          'createWorkspaceVariableCategorizationDescription',
        )}
      />

      <DataType />

      {isErrorStagingAreaHierarchies || isErrorStagingAreaAggregations ? (
        <ContainerMaintenance
          content="content"
          style={{ marginTop: '3rem' }}
          data-testid="error-staging-area-hierarchies"
        />
      ) : isEdition &&
        (isLoadingStagingAreaHierarchies ||
          loadingCategorization ||
          isLoadingStagingAreaAggregations) ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton
          style={{ height: '30rem' }}
          data-testid="loading-hierarchies"
        />
      ) : (
        <Content>
          <CategorizationActions>
            {(!!tableRowData.length || editingCategorization) && (
              <MarketShareToggle>
                <ToggleSwitch
                  label="Market Share"
                  checked={enableMarketShare}
                  onClick={() => setEnableMarketShare(!enableMarketShare)}
                  information={translate(
                    'createWorkspaceEnableMarketShareInformation',
                  )}
                  disabled={marketShareDisabled}
                />
              </MarketShareToggle>
            )}

            {!!tableRowData.length && !editingCategorization && (
              <ButtonRounded
                label={translate('export')}
                icon={<DownloadSimple />}
                onClick={handleExportTable}
                disabled={false}
                data-testid="button-export-categorization"
              />
            )}

            {((editingCategorization && !!tableRowData.length) ||
              !!tableRowData.length) && (
              <ButtonRounded
                label={translate(
                  editingCategorization
                    ? 'createWorkspaceCancelCategorizationEdition'
                    : 'discard',
                )}
                icon={<X />}
                onClick={() => {
                  setShowDiscardConfirmation(true);
                }}
                disabled={false}
                data-testid="button-discard-categorization"
              />
            )}

            {!!tableRowData.length && !editingCategorization && (
              <ButtonRounded
                label={translate('editCategorization')}
                icon={<PencilSimple />}
                onClick={() => setEditingCategorization(true)}
                disabled={false}
                data-testid="button-edit-categorization"
              />
            )}
          </CategorizationActions>

          {editingCategorization ||
          (!categorization?.hierarchies.length &&
            (!currentFile || fileIsEmpty)) ? (
            // eslint-disable-next-line react/jsx-indent
            <>
              <UploadContainer data-testid="container-upload-categorization">
                <Subtitle style={{ marginBottom: '0.5rem' }}>
                  <div>2</div>
                  <h3>{translate('workspaceConfigCategorization')}</h3>
                </Subtitle>

                <InputFile
                  title={translate(
                    'createWorkspaceCreateCategorizationUploadTitle',
                  )}
                  fileFormats={['.xlsx']}
                  file={currentFile}
                  setFile={setCurrentFile}
                  error={fileIsEmpty ? translate('fileIsEmpty') : undefined}
                  dataCy="input-upload-categorization"
                >
                  <FileButtons>
                    <Button
                      buttonType="naked"
                      icon={<Question />}
                      onClick={() => setShowRequirementsModal(true)}
                      data-testid="button-see-requirements"
                    >
                      {translate('fileInputSeeRequirements')}
                    </Button>
                    <Button
                      onClick={handleDownloadTemplate}
                      buttonType="naked"
                      icon={<DownloadSimple />}
                      className="naked-button buttonDownloadTemplate"
                      data-testid="button-download-template"
                    >
                      {translate('templateDownload')}
                    </Button>
                  </FileButtons>
                </InputFile>
              </UploadContainer>
            </>
          ) : loadingTableData ||
            (categorization?.hierarchies.length && !tableRowData.length) ||
            updatingCategorization ? (
            // eslint-disable-next-line react/jsx-indent
            <>
              <Subtitle>
                <div>2</div>
                <h3>{translate('workspaceConfigCategorization')}</h3>
              </Subtitle>
              <ContainerSkeleton
                data-testid="loading-filters"
                style={{ height: '15rem' }}
              />
            </>
          ) : (
            <TableContainer>
              <Subtitle>
                <div>2</div>
                <h3>{translate('workspaceConfigCategorization')}</h3>
              </Subtitle>

              <AgGridTable
                tableRef={tableRef}
                rowData={tableRowData}
                columnDefs={tableColumnDefs}
                defaultColDef={defaultColDef}
                enableRangeHandle={false}
                enableRangeSelection={false}
                suppressCellFocus
                suppressMenuHide
                suppressContextMenu
                excelStyles={excelStyle}
                maxRemHeight={20}
                onFirstDataRendered={() => setTableRendered(true)}
                checkDomLayoutType={false}
              />
            </TableContainer>
          )}

          <AggregationConfiguration />
        </Content>
      )}

      <StepFooter
        stepQtty={4}
        selectedStep={4}
        backStep={{
          text: translate('step3'),
          onClick: backStep,
          disabled: false,
        }}
        nextStep={{
          text: translate(
            isEdition ? 'editWorkspaceSaveChanges' : 'createWorkspaceCreate',
          ),
          onClick: nextStep,
          disabled: enableMarketShare && !tableRowData.length,
          removeIcon: isEdition,
        }}
        discardButton={
          isEdition
            ? {
                text: translate('discard'),
                onClick: () =>
                  navigate(`/workspaces/${workspaceId}/control-panel`),
                disabled: false,
              }
            : undefined
        }
      />

      {showRequirementsModal && (
        <FileRequirementsModal setVisible={setShowRequirementsModal} />
      )}

      {showDiscardConfirmation && (
        <ConfirmCategorizationDiscardModal
          setVisible={setShowDiscardConfirmation}
          handleDiscard={handleDiscard}
        />
      )}

      <Tooltip
        id="variable-categorization-tooltip"
        className="categorizationTooltip customTooltipTheme"
      />
    </Container>
  );
};

import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { CheckCircle } from 'phosphor-react';

import { RequirementsModalProps } from './types';
import { Content, Item, List } from './styles';

export const RequirementsModal: React.FC<RequirementsModalProps> = ({
  setVisible,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal
      visible
      setVisible={setVisible}
      style={{ width: '45rem' }}
      dataCy="modal-see-requirements"
    >
      <Content>
        <Card
          textCard={translate('mySeriesRequirementsTitle')}
          textDescription={translate('categorizationRequirementsDescription')}
        />

        <List>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('mySeriesRequirements1')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('mySeriesRequirements2')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('mySeriesRequirements3')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('mySeriesRequirements4')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('mySeriesRequirements5')}</p>
          </Item>
        </List>
      </Content>
      <ModalFooter>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-testid="button-ok-file-requirements"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
